<script>
    

    export default {
    name: 'DrawingHistory',
    components: {
        DrawingHistoryComponent: () => import('./DrawingHistoryComponent'),
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            
        }
    },
    props:[
        'currentType',
    ],
    
    methods: {
        
    },    
    provide:function(){
        
    },
    mounted() {
        
    },
    computed:{
        
    },
    watch:{
        
    }
};
</script>
<template>
    <DrawingHistoryComponent :currentType='currentType' />
</template>
<style lang='scss' scoped type="text/css">

</style>